import React ,{Component} from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import Fbicon from '../images/fb-icon.png';
import Twittericon from '../images/twitter.png';
import Instaicon from '../images/instagram.png';
import Paypalimg from '../images/paypal.png';
import Visaimg from '../images/visa.png';
import Mastercardimg from '../images/mastercard.png';
import Americanimg from '../images/american.png';
// get our fontawesome imports
import { faHome  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../axios";

class Footer extends Component {
      constructor () {
        super()
        this.state = {
         getreturnData: {},
           loading: true
        }
      }
componentDidMount () {
this.setState({ loading: true }, () => {
axios.get('/cms/cms-meta/2/').then(response => {
        this.setState({ getContactdata : response.data.data, loading : false }, () => {});
}).catch(err => {
    console.log("ERROR: " + err);
 });
});
}
render () {
const { getContactdata } = this.state;
if(typeof getContactdata == 'undefined') {
return '';
}
const facebookLink = getContactdata.cmsmetaapi.meta_value[0].facebook 
const instagramLink = getContactdata.cmsmetaapi.meta_value[0].instagram 
const twitterLink = getContactdata.cmsmetaapi.meta_value[0].twitter 
    return (
        <footer>
            <div class="container">
                <div className="row">
                    <div class="col-md-3 col-sm-12 p-0">
                        <h4>Information</h4>
                        <ul className='list-unstyled p-0 m-0'>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact-us/">Contact Us</Link></li>
                            <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                            <li><Link to="/return-policy/">Terms of sale & Delivery Returns</Link></li>
                          
                        </ul>
                    </div>
                    <div class="col-md-6 col-sm-12 p-0 pl-4">
                        <h4>Product Categories</h4>
                        <ul className='list-unstyled p-0 m-0 d-flex flex-wrap product-cat-link'>
                            <li>Custom Snapbacks</li>
                            <li>Custom 5 Panel Hats</li>
                            <li>Custom Golf Hats</li>
                            <li>Custom Fitten Hats</li>
                            <li>Custom Trucker Hats</li>
                            <li>Custom Military Hats</li>
                            <li>Custom Dad Hats</li>
                            <li>Custom Bucket Hats</li>
                            <li>Custom Mesh Hats</li>
                            <li>Custom Camo Hats</li>
                            <li>Custom Baseball hats</li>
                            <li>Custom Flat Brim Hats</li>
                            <li>Custom Beanies</li>
                            <li>Custom Athletic Hats</li>
                            <li>Deals</li>
                        </ul>
                    </div>
                    <div className='col-md-3 col-sm-12 p-0'>
                        <ul className='list-unstyled p-0 m-0 d-inline-flex w-100 justify-content-end align-items-center social-list'>
                            <li className='m-0 mr-4'><a target="_blank" href={facebookLink}><img src={Fbicon} alt='' /></a></li>
                            <li className='m-0 mr-4'><a target="_blank" href={twitterLink ?twitterLink:'Twitter' }><img src={Twittericon} alt='' /></a></li>
                            <li className='m-0 mr-4'><a target="_blank" href={instagramLink ? instagramLink: 'twitter'}><img src={Instaicon} alt='' /></a></li>
                        </ul>
                    </div>

                    <div className='footer-bottom d-inline-flex w-100 align-items-center justify-content-between pt-4 mt-3'>
                        <div className='left-bottom d-inline-flex align-items-center'>
                            <Link to="/"><img src={Logo} alt='' /></Link>
                            <p className='m-0 ml-4 pl-3'>© Punchly 2021. All rights reserved.</p>
                        </div>
                        <div className='right-bottom'>
                            <ul className='d-inline-flex list-unstyled align-items'>
                                <li className='mb-0 mr-3'><img src={Paypalimg} alt='' /></li>
                                <li className='mb-0 mr-3'><img src={Visaimg} alt='' /></li>
                                <li className='mb-0 mr-3'><img src={Mastercardimg} alt='' /></li>
                                <li className='mb-0'><img src={Americanimg} alt='' /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
}
export default Footer;