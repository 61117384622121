import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from "../axios";
import Loading from "react-loader-spinner";

class About extends Component {
      constructor () {
        super()
        this.state = {
          getAboutata: {},
           loading: true
        }
      }
componentDidMount () { 
axios.get('/cms/cms-meta/3/').then(response => {
        this.setState({getAboutata : response.data.data, loading:false })
}).catch(err => {
    console.log("ERROR: " + err);
 })
}
 render () {
    const {getAboutata} = this.state;
    const style = {textAlign: 'center',height: 600, flex: 1,marginTop:240, flex: 1,justifyContent: 'center',alignItems:'center'};  
    if(Object.keys(getAboutata).length === 0) {
    return <div style={style}><Loading
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} 
      /></div>;

  }
    console.log(getAboutata.cmsmetaapi.meta_value);
    const main_title = getAboutata.cmsmetaapi.meta_value[0].title;
    const description = getAboutata.cmsmetaapi.meta_value[0].description;
    const  rightImage =  getAboutata.home_path+'/'+getAboutata.cmsmetaapi.meta_value[0].right_image;
    const  leftcontent =  getAboutata.cmsmetaapi.meta_value[0].leftcontent;
    const BannerImageUrl =  getAboutata.home_path+'/'+getAboutata.cmsmetaapi.meta_value[0].banner_image;  
    return (
        <>
            <section className='page-heading-main' style={{
              backgroundImage: `url(${(BannerImageUrl && BannerImageUrl)})`
            }}>
                <h1 className='text-center text-white'>{main_title}</h1>
                <p className='text-center text-white m-0'>{description}</p>
            </section>
            <section className='about-content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 img-shadow shadow-bg-change'>
                            <img className='img-fluid' src={rightImage && rightImage} alt='' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 pl-5' dangerouslySetInnerHTML={{__html: leftcontent}} />
                    </div>
                </div>
            </section>
        </>
    );
}
}

export default withRouter(About);