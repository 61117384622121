import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Contact from './components/Contact';
import Blog from './components/Blog';
import About from './components/About';
import BlogDetail from './components/BlogDetail';
import ReturnPolicy from './components/ReturnPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import { Route, Link, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';

function App() {
  return (
    <div className="wrapper">
      <Router>
          <Header />
          <Switch>
              <Route exact path="/" component={withRouter(HomePage)} />
              <Route exact path="/contact-us" component={withRouter(Contact)} />
              <Route exact path="/blog" component={withRouter(Blog)} />
              <Route exact path="/blog/:id" component={withRouter(BlogDetail)} />
              <Route exact path="/about" component={withRouter(About)} />
               <Route exact path="/privacy-policy" component={withRouter(PrivacyPolicy)} />
              <Route exact path="/return-policy" component={withRouter(ReturnPolicy)} />
               <Route path='/login' component={() => { 
                 window.location.href = 'https://emb.myfileshosting.com/login'; 
            }}/>
            <Route path='/register' component={() => { 
                 window.location.href = 'https://emb.myfileshosting.com/register'; 
            }}/>

          </Switch>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
