import React ,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Blogbanner from '../images/blog-detail-banner.jpg';
import Blogimgone from '../images/detail-img1.png';
import Blogimgtwo from '../images/detail-img2.png';
import Blogimgthree from '../images/detail-img3.png';
import Blogimgfour from '../images/detail-img4.png';
import Blogimgfive from '../images/detail-img5.png';
import Blogimgsix from '../images/detail-img6.png';
import Blogimgseven from '../images/detail-img7.png';
import Blogimgeight from '../images/detail-img8.png';
import Videothumb from '../images/video-img.png';
import Seotextimg from '../images/seo-text.png';
import axios from "../axios";
import Loading from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-regular-svg-icons";
import {faAngleRight, faAngleLeft} from "@fortawesome/free-solid-svg-icons";
class BlogDetail extends Component {
      constructor (props) {
        super(props)
        this.state = {
          blogsDetail: {},
          getHomedata: {},
          getBlogdata: {},
          showNextButton : true,
          loading :true
        }
      }
componentDidMount () {
    this.setState({ loading: true }, () => {
      const id = this.props.match.params.id
        axios.get('/cms/blog-single/'+id).then(response => {
          this.setState({
            blogsDetail: response.data.data,
            loading:false
          });
        });
        axios.get('/cms/cms-meta/1/').then(response => {
        this.setState({getHomedata : response.data.data })
            }).catch(err => {
                console.log("ERROR: " + err);
        });
});
}

handleNext = (id,e) => {
axios.get('/cms/blog/')
      .then(response => this.setState({
        getBlogdata : response.data.data
      } , ()=> {
    const { getBlogdata } = this.state;
    const post_id = this.props.match.params.id;   
     const index = this.state.getBlogdata.findIndex((element, index) => {
        const sizeOfBlogs = this.state.getBlogdata.length;
        if (element.id === id) { 
            index = index + 1; 
              if(sizeOfBlogs > index) {
                this.setState({blogsDetail : getBlogdata[index], showNextButton : true } , () => { });
              }
              else {
              this.setState({showNextButton : false })          
            }
          }
        })
    }));
}

render () {
    const {blogsDetail, getHomedata, showNextButton, loading} = this.state;
    const style = {textAlign: 'center',height: 600, flex: 1,marginTop:240, flex: 1,justifyContent: 'center',alignItems:'center'};  
     if(typeof blogsDetail == 'undefined' || Object.keys(blogsDetail).length < 1 || typeof getHomedata == 'undefined' || Object.keys(getHomedata).length < 1){
        return <div style={style}><Loading
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} 
      /></div>;
     }
    console.log('Success');
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const now = new Date(blogsDetail.created_at);
    const posted_date = (months[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear());
    const  SectionSubscription =  getHomedata.cmsmetaapi.meta_value[0].section_seven_description;            
    const  SectionSubbackImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_seven_image;
    const  SectionEightDescription =  getHomedata.cmsmetaapi.meta_value[0].section_eight_description;            
    const  SectionEightImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_eight_image; 
    const  blog_description =  blogsDetail.blog_description; 
    return (
        <>
        <section className='blog-detail-banner'>
           <img className='img-fluid w-100' src={blogsDetail.feature_image} alt='' />
            </section>                    
                <section className='back-next-nav m-auto'>
                <div className='container mt-4 pt-3'>
                    <div className='row'>
                        <div class="col-md-6 col-sm-12 p-0">
                            <Link className='d-inline-flex align-items-center' to='/blog'><span><FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon></span> Back to Punchly Blog</Link>
                        </div>
                        <div class="col-md-6 col-sm-12 d-flex justify-content-end p-0">                         
                            { showNextButton && <a href={void(0)}className='d-inline-flex align-items-center' onClick={()=>{this.handleNext(blogsDetail.id)}} >Next article <span className='next-article'><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span></a>}
                        </div>
                    </div>
                </div>
            </section>
          
             <section className='blog-detail-content m-auto'>
                <div className='container mt-4 pt-3'>
                    <div className='row'>
                        <div className='col-md-12 p-0 inner-container'>
                        <p className='category-tag purple-bg text-center'><span>{!!(blogsDetail.blog_cat.name)?blogsDetail.blog_cat.name:""}</span></p>
                            <h1>{blogsDetail.blog_title}</h1>
                            <span className='date-item text-center mt-4 d-block'><FontAwesomeIcon icon={faCalendarCheck}></FontAwesomeIcon> {posted_date}</span>
                            <div dangerouslySetInnerHTML={{__html: blog_description}} />
                            </div>
                    </div>
                </div>
            </section>
            <section className='subscribe subscribe-inner-pages' style={{
              backgroundImage: `url(${(SectionSubbackImage && SectionSubbackImage)})`
            }}>
                <div class="container">
                    <div class="row flex-column" dangerouslySetInnerHTML={{__html: SectionSubscription}} />
                        
                        <form className='w-100 m-auto'>
                            <div className='d-inline-flex align-items-center justify-content-between w-100'>
                                <input type='text' placeholder='Enter your email address' />
                                <input className='btn btn-common bg-white' type='button' value='Subscribe' />
                            </div>
                        </form>                
                </div>
            </section>
            <section className='seo-text'>
                <div className='container'>
                <div className='col-md-12 row m-0 align-items-center pt-custom'>
                        <div className='col-md-6 col-sm-12 p-0 left-work' dangerouslySetInnerHTML={{__html: SectionEightDescription}} />
                        <div className='col-md-6 col-sm-12 p-0 d-inline-flex justify-content-end'>
                            <div className='img-shadow d-inline-block shadow-bg-change'>
                                <img className='img-fluid' src={SectionEightImage} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
}
export default withRouter(BlogDetail);