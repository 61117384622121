import React, { Component, useEffect, useState } from 'react';
import '../Main.css';
import Logo from '../images/logo.png';
import Contact from './Contact';
import HomePage from './HomePage';
import About from './About';
import { Route, Link, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import axios from "../axios";
class Header extends Component {
      constructor () {
        super()
        this.state = {
        }
      }

componentDidMount () { 
window.onscroll = function() {myFunction()};
var navbar = document.getElementById("myheader");
var sticky = navbar.offsetTop;
function myFunction() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
}
}
render () {
    return (
           <header className='header' id='myheader'>
                 <div className='container'>
                    <div class="row justify-content-between align-items-center">
                        <div className='logo'>
                            <Link to="/"><img src={Logo} alt='' /></Link>
                        </div>
                        <div className='main-nav' id='main-nav'>
                            <ul className='list-unstyled d-flex align-items-center m-0'>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li className='mr-2'><Link className='btn btn-common btn-gray' to="/login">Login</Link></li>                               
                                <li><Link className='btn btn-common btn-green' to="/register">Register</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
           </header>
    );
}
}
export default Header;