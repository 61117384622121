import React ,{Component} from 'react';
import '../Main.css';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import axios from "../axios";
import ReactHtml from 'raw-html-react';
import Loading from "react-loader-spinner";
class HomePage extends Component {
      constructor () {
        super()
        this.state = {
          getHomedata: {},
           loading: false
        }
      }

componentDidMount () { 
 this.setState({ loading: true }, () => {
axios.get('/cms/cms-meta/1/').then(response => {
        this.setState({getHomedata : response.data.data,  loading: false })
}).catch(err => {
    console.log("ERROR: " + err);
 })
}
)}

render () {
    const {getHomedata , loading} = this.state;
    const style = {textAlign: 'center',height: 600, flex: 1,marginTop:240, flex: 1,justifyContent: 'center',alignItems:'center'};  
     if(typeof getHomedata.cmsmetaapi == 'undefined'){
        return  <div style={style}>
        <Loading
        type="Puff"
        color="#00BFFF"
        height={80}
        width={80}
        timeout={3000} //3 secs
      /></div>;
}


    const  BannerTitle =  getHomedata.cmsmetaapi.meta_value[0].banner_title;
    const  BannerDescription =  getHomedata.cmsmetaapi.meta_value[0].banner_description;
    const  BannerLink =  getHomedata.cmsmetaapi.meta_value[0].banner_button_link;    
    const  BannerButtonName =  getHomedata.cmsmetaapi.meta_value[0].banner_button_name;   
    const  BannerImageUrl =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].banner_image;     
    const  FrontImageUrl =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].front_image; 
    const  SectionTwoDescription =  getHomedata.cmsmetaapi.meta_value[0].section_two_description;    
    const  SectionthreeDescription =  getHomedata.cmsmetaapi.meta_value[0].section_three_left_description; 
    const  SectionthreeImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_three_right_image;  
    const  SectionFourDescription =  getHomedata.cmsmetaapi.meta_value[0].section_four_description;            
    const  SectionFourImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_four_image; 
    const  SectionFiveDescription =  getHomedata.cmsmetaapi.meta_value[0].section_five_description;            
    const  SectionFiveImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_five_image; 
    const  SectionSixDescription =  getHomedata.cmsmetaapi.meta_value[0].section_six_description;            
    const  SectionSixImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_six_image;  
    const  SectionSubscription =  getHomedata.cmsmetaapi.meta_value[0].section_seven_description;            
    const  SectionSubbackImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_seven_image;
    const  SectionEightDescription =  getHomedata.cmsmetaapi.meta_value[0].section_eight_description;            
    const  SectionEightImage =  getHomedata.home_path+'/'+getHomedata.cmsmetaapi.meta_value[0].section_eight_image;     
    return ( 
        <>
   <section className='welcome'> 
                <div className='welcome-bg' style={{
              backgroundImage: `url(${(BannerImageUrl && BannerImageUrl)})`
            }}></div>
                <div className='container text-center'>
                <h1 className='text-white' dangerouslySetInnerHTML={{__html: BannerTitle}}  />            
                   <p className='text-white' dangerouslySetInnerHTML={{__html: BannerDescription}} />
                    <Link className='btn btn-common bg-white' to={BannerLink}>{BannerButtonName}</Link>
                    <div className='hero-img'>
                        <div className='hero-img-inner img-shadow'>
                            <img className='img-fluid' src={FrontImageUrl} alt='' />
                        </div>
                    </div>
                </div>
            </section>
            <section className='how-work'>
                <div className='container'>
                    <div className='row'>
                <div className='w-100' dangerouslySetInnerHTML={{__html: SectionTwoDescription}} />
                  <div className='col-md-12 row m-0 align-items-center pt-custom'>
                <div className='col-md-5 col-sm-12 pl-0 left-work pr-custom' dangerouslySetInnerHTML={{__html: SectionthreeDescription}} />
                   <div class='col-md-7 col-sm-12 p-0 d-inline-flex justify-content-end'>
                            <div class='img-shadow d-inline-block shadow-half shadow-bg-change'>
                            <img class='img-fluid' src={SectionthreeImage} alt='' />
                            </div>
                            </div>
                         
                        </div>
                        <div className='col-md-12 row m-0 align-items-center pt-custom flex-row-reverse'>
                        <div className='col-md-5 col-sm-12 pr-0 left-work pl-custom' dangerouslySetInnerHTML={{__html: SectionFourDescription}} />

                            <div className='col-md-7 col-sm-12 p-0'>
                                <div className='img-shadow d-inline-block shadow-half shadow-height-inc shadow-bg-change'>
                                    <img className='img-fluid' src={SectionFourImage} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 row m-0 align-items-center pt-custom'>
                            <div className='col-md-5 col-sm-12 pl-0 left-work pr-custom' dangerouslySetInnerHTML={{__html: SectionFiveDescription}} />

                            <div className='col-md-7 col-sm-12 p-0 d-inline-flex justify-content-end'>
                                <div className='img-shadow d-inline-block shadow-half shadow-height-inc shadow-bg-change'>
                                    <img className='img-fluid' src={SectionFiveImage && SectionFiveImage} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 row m-0 align-items-center pt-custom flex-row-reverse'>
                            <div className='col-md-5 col-sm-12 pr-0 left-work pl-custom' dangerouslySetInnerHTML={{__html: SectionSixDescription}} />
                            
                            <div className='col-md-7 col-sm-12 p-0'>
                                <div className='img-shadow d-inline-block shadow-half shadow-height-inc shadow-bg-change'>
                                    <img className='img-fluid' src={SectionSixImage && SectionSixImage} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='subscribe'  style={{
              backgroundImage: `url(${(SectionSubbackImage && SectionSubbackImage)})`
            }}>
                <div class="container">
                    <div class="row flex-column" dangerouslySetInnerHTML={{__html: SectionSubscription}} />
                     <form className='w-100 m-auto'>
                            <div className='d-inline-flex align-items-center justify-content-between w-100'>
                                <input type='text' placeholder='Enter your email address' />
                                <input className='btn btn-common bg-white' type='button' value='Subscribe' />
                            </div>
                        </form>
                </div>
            </section>
            <section className='seo-text'>
                <div className='container'>
                <div className='col-md-12 row m-0 align-items-center pt-custom'>
                        <div className='col-md-6 col-sm-12 p-0 left-work' dangerouslySetInnerHTML={{__html: SectionEightDescription}} />
                        <div className='col-md-6 col-sm-12 p-0 d-inline-flex justify-content-end'>
                            <div className='img-shadow d-inline-block shadow-bg-change'>
                                <img className='img-fluid' src={SectionEightImage} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    );
}
}
export default withRouter(HomePage);